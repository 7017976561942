.toggle-switch {
	position: relative;
	width: 49px;
	height: 25px;
	display: inline-block;
	
	&.toggle-switch__rounded {
		.slider {
			border-radius: 90px;
			background: #f1f1f1;
			// border: 1px solid #A0A0A0;
			
			&:before {
				border-radius: 50%;
			}
		}
	}
	
	.toggle-switch__wrapper {
		position: relative;
		width: 49px;
		height: 25px;
		
		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: .4s ease;
			box-shadow: inset 0px 1px 1px rgba(0,0,0,0.25);
			
			&:before {
				width: 21px;
				height: 21px;
				position: absolute;
				background: #fff;
				content: '';
				margin: 0;
				padding: 0;
				top: 12px;
				left: 0.10rem;
				transform: translateY(-50%);
				transition: .4s;
				cursor: pointer;
				box-shadow: 0px 1px 1px rgba(0,0,0,0.1);
			}
			
			&.is-checked {
				background: #42B640;
				
				&:before {
					left: calc(100% - 23px);
				}
			}
		}
	}
}